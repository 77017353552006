
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        










































































































.homepage-installation {
  background-color: $c-cultured;
}

.homepage-installation-inner {
  overflow: hidden;
  padding-bottom: $spacing * 1.5 !important;
}

.homepage-installation__title {
  @extend %fw-bold;

  margin: 0 0 $spacing * 2.5;
  text-align: center;

  strong {
    @extend %fw-black;
  }

  @include mq(l) {
    margin: 0 0 $spacing * 3.5;
  }
}

.homepage-installation__items {
  margin-bottom: 5rem;

  ::v-deep {
    .carousel__item {
      @extend %text-center;

      position: relative;
      width: 100%;
      padding: 0 $spacing * 3;
      color: $c-gray-darkest;
    }

    @include mq(l) {
      .carousel__scroller {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
      }
    }
  }
}

.homepage-installation__item__icon {
  max-height: 5rem;
  margin-bottom: $spacing * 0.5;
  fill: $c-pink-medium;
}

.homepage-installation__item__title {
  @extend %fw-semi;

  font-family: $ff-alt;
  font-size: 2.4rem;
  line-height: 3rem;
}

.homepage-installation__item__text {
  @extend %fw-light;

  font-family: $ff-default;
  font-size: 1.8rem;
  line-height: 3rem;
}

.homepage-installation__item__arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;
  display: block;
  transform: translate(50%, -50%);
  width: 8rem;
  height: 8rem;

  // background-color: rebeccapurple;

  @include mq(l) {
    transform: translate(75%, -50%);
  }
}
