
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























































































































































































































































































































































/* stylelint-disable declaration-no-important */
.homepage-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 52rem;
  background: $c-blue-darkest;

  ::v-deep .btn__link__title {
    @include mq($until: m) {
      font-size: 1.6rem;
    }
  }

  @include mq(m) {
    justify-content: center;
    height: 90vh;
  }

  @include mq(xl) {
    padding-top: 20rem !important;
  }
}

.homepage-media {
  @include get-all-space;

  position: fixed;
  overflow: hidden;
  pointer-events: none;

  video {
    @include get-all-space;

    object-fit: cover;
    object-position: top center;

    @include mq(m) {
      object-position: top left;
    }
  }

  @include mq($until: m) {
    height: 52rem !important;
    clip-path: none !important; // TODO
  }

  @include mq(m) {
    &::after {
      @include get-all-space;

      content: '';
      background: linear-gradient(
        89.93deg,
        rgba($black, 0.7) 1.7%,
        rgba($black, 0) 69.25%
      );
    }
  }
}

.homepage-hero-inner {
  position: relative;
  z-index: 1;
  width: 100%;
}

.homepage-hero__headline {
  @extend %text-uppercase;

  color: $white;
  font-family: $ff-alt;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 1px;
}

.homepage-hero__title {
  @include fluid(
    font-size,
    (
      s: 40px,
      l: 70px,
    )
  );

  max-width: 60rem;
  margin: 0;
  margin-top: $spacing * 1.25;
  color: $white;
  font-family: $ff-alt;
  font-weight: 600;
  line-height: 1.15;
}

.homepage-hero__subtitle {
  @include fluid(
    font-size,
    (
      s: 18px,
      l: 24px,
    )
  );

  margin-top: $spacing;
  color: $white;
  font-family: $ff-alt;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

.homepage-hero__btn {
  @include fluid(
    margin-top,
    (
      s: 24px,
      l: 44px,
    )
  );

  // prettier-ignore
  @include fluid(padding-top, (s: 12px, l: 20px));

  // prettier-ignore
  @include fluid(padding-bottom, (s: 12px, l: 20px));

  background-color: transparent;
  border: 3px solid rgba($white, 0.6);

  &:hover {
    background-color: $c-green-light;
    border-color: transparent;
  }
}

.homepage-hero__video-outer,
.homepage-hero__picture-outer {
  @include get-all-space;
}

.homepage-hero__video-outer {
  max-width: 100vw;
}

.homepage-hero__video,
.homepage-hero__picture {
  @include image-fit;

  top: 0;
  max-height: 100vh;
}

.homepage-hero__scroll-outer {
  position: absolute;
  z-index: 99;
  right: $spacing * 2.5;
  bottom: $spacing * 4;
  display: none;

  body:not([data-template='homepage']) & {
    display: none;
  }

  @include mq(l) {
    display: block;
  }
}

::v-deep {
  .homepage-media:after {
    display: none;
  }
}
