
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


























































































.homepage-configurator {
  position: relative;
  overflow: hidden;

  ::v-deep .rgu-selection {
    @include mq($until: m) {
      width: calc(100% - 40px);
      padding-top: $spacing * 2;
      padding-bottom: $spacing * 2;
      background: $white;
      // border: 1px solid $c-light-gray;
      // border-radius: 15px;
      // box-shadow: 0 4px 10px rgba(0, 0, 0, 5%);
    }
  }

  ::v-deep {
    .rgu-selection {
      padding-top: 0 !important;
    }

    .rgu-selection__speedlines-container {
      display: none;
    }

    .rgu-selection__error {
      @include mq($until: m) {
        text-align: left;
      }
    }
  }
}

.homepage-configurator-inner {
  // @include fluid(
  //   padding-top,
  //   (
  //     s: 50px,
  //     l: 110px,
  //   )
  // );

  // @include fluid(
  //   padding-bottom,
  //   (
  //     s: 10px,
  //     l: 35px,
  //   )
  // );

  ::v-deep {
    .rgu-selection__title,
    .rgu-selection__subtitle,
    .price-label {
      color: $c-blue-dark;
    }

    .price-label__price::after {
      background-color: $c-blue-dark;
    }
  }

  @include mq($until: m) {
    ::v-deep {
      .rgu-selection__title,
      .rgu-selection__subtitle {
        text-align: center;
      }
    }
  }
}

.homepage-configurator-speedlines {
  z-index: -1;
  width: 100%;

  @include mq($until: l) {
    display: none;
  }
}

.homepage-configurator__trust {
  margin: $spacing * 2.5 0;
  text-align: center;
}
