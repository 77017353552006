
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        











































































.homepage-contact {
  overflow: hidden;
  background-color: $c-cultured;

  ::v-deep .card-small-item__text p {
    margin: 0;
  }
}

.homepage-contact__title {
  margin: 0;
  text-align: center;
}

.homepage-contact__subtitle {
  margin: 0 auto 3.8rem;
  text-align: center;

  @include mq($until: m) {
    max-width: 30rem;
  }

  @include mq(m) {
    margin: 0 auto $spacing * 2;
    font-size: 2.4rem;
  }
}

::v-deep {
  .carousel__item {
    @include mq($until: l) {
      width: 90%;
      max-width: none;
    }

    @include mq(l) {
      width: 31%;
      min-width: 25%;
    }
  }
}

.homepage-contact__cards {
  margin-top: $spacing * 1.5;

  @include mq(l) {
    margin-top: $spacing * 3;
  }
}

.homepage-contact__card {
  width: 100%;
  max-width: none;
  min-height: 100%;
  margin: 0;

  ::v-deep {
    .card-small-item__icon {
      width: 6rem;
      height: 4rem;
    }

    .shared-icons-messenger-twitter {
      width: 8.5rem;
      stroke-width: 0.7;
      stroke: $c-pink-medium;
    }
  }
}

.homepage-contact__card__icon {
  margin-bottom: $spacing * 0.5;
  fill: $c-pink-medium;
}

.homepage-contact__card__title {
  @extend %fw-semi;

  font-family: $ff-alt;
  font-size: 2.4rem;
  line-height: 3rem;
}

.homepage-contact__card__text {
  @extend %fw-light;

  font-family: $ff-default;
  font-size: 1.8rem;
  line-height: 3rem;
}
