
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

















































































































































































































































































/* stylelint-disable declaration-no-important */
.homepage-mosaic {
  position: relative;
  padding-bottom: 1rem !important;

  @include mq(l) {
    padding-top: 14rem !important;
    padding-bottom: 3rem !important;
  }
}

.homepage-mosaic__title {
  @extend %text-center;

  max-width: 75rem;
  margin: 0 auto 2rem;

  @include mq($until: m) {
    max-width: 30rem;
    margin: 0 auto 1rem;
  }
}
.homepage-mosaic__subtitle {
  @extend %text-center;

  margin: 0 0 2.8rem;
  font-family: $ff-alt;
  font-size: 2.4rem;
  font-weight: 300;

  @include mq($until: m) {
    ::v-deep {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }
}

.homepage-mosaic__items {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: $spacing * 3;
  outline: none;
  @include mq($until: m) {
    ::v-deep {
      margin-top: $spacing;
    }
  }

  ::v-deep {
    .flickity-viewport {
      width: 100%;
      outline: none;
    }

    .flickity-slider {
      display: flex;
      width: 100%;
      height: 100%;
      outline: none;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }
  }
}

.homepage-mosaic__cards {
  // prettier-ignore
  @include fluid(height,(s: 350px,l: 720px,));

  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  width: 66%;
  margin-left: 1.8rem;

  &.is-solo {
    @extend %text-center;

    // height: 100%;
  }

  @include mq(l) {
    width: 33%;
    gap: 3rem;
    margin-left: 3rem;
  }
}

.homepage-mosaic__card {
  // prettier-ignore
  @include fluid(height,(s: 165px,l: 350px,));

  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-basis: 50%;
  overflow: hidden;
  padding: $spacing;
  background-color: $c-gray-light;
  border-radius: 2rem;

  .is-solo & {
    // prettier-ignore
    @include fluid(height,(s: 350px,l: 720px,));

    flex-basis: 100%;
    padding: $spacing * 1.5;

    @include mq(l) {
      padding: $spacing * 3.5 $spacing * 1.5;
    }

    @include mq(xxl) {
      padding: $spacing * 3.5;
    }
  }

  @include mq(l) {
    padding: $spacing * 2;
  }
}

.homepage-mosaic__card-inner {
  @include mq(l) {
    transform: translateY(8rem);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.54, 1);

    .homepage-mosaic__card:hover & {
      transform: translateY(0);
    }
  }
}

.homepage-mosaic__card__headline {
  @extend %text-uppercase;

  color: $white;
  font-family: $ff-alt;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 1px;

  @include mq(l) {
    font-size: 1.4rem;
  }
}

.homepage-mosaic__card__title {
  margin: 0;
  margin-top: 3px;
  color: $white;
  font-family: $ff-alt;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.29;

  .is-solo & {
    font-size: 2.4rem;
    line-height: 1.21;

    @include mq(l) {
      font-size: 4.4rem;
    }
  }

  @include mq(l) {
    margin-top: $spacing * 0.5;
    font-size: 2.8rem;
  }
}

.homepage-mosaic__card__picture-outer {
  @include get-all-space;

  z-index: -1;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.54, 1);

  .homepage-mosaic__card:hover & {
    transform: scale(1.1);
  }

  &::before,
  &::after {
    @include get-all-space;

    z-index: 10;
    content: '';
    display: block;
    transition: all 0.2s ease-in-out;
  }

  &::before {
    // prettier-ignore
    background: linear-gradient(0.4deg, rgba(0, 0, 0, 75%) 22%, rgba(0, 0, 0, 5%) 83.57%);
    opacity: 0;

    .homepage-mosaic__card:hover & {
      // prettier-ignore
      opacity: 1;
    }
  }

  &::after {
    // prettier-ignore
    background: linear-gradient(0.4deg, rgba($black, 0.8) 1.97%, rgba($black, 15%) 56.57%);

    .homepage-mosaic__card:hover & {
      opacity: 0;
    }
  }
}

.homepage-mosaic__card__picture {
  ::v-deep .picture__image {
    /* MOBILE PDM_0343 1 */
    @include image-fit;
  }
}

.homepage-mosaic__card__link {
  @include get-all-space;

  background: none;

  ::v-deep span {
    @extend %visually-hidden;
  }
}

.homepage-mosaic__card__btn {
  width: fit-content;
  margin-top: $spacing;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.54, 1);

  .is-solo & {
    margin: $spacing auto 0;
  }

  .homepage-mosaic__card:hover & {
    opacity: 1;
  }

  @include mq($until: l) {
    display: none;
  }
}
