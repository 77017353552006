
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
































































::v-deep {
  .promo-hero__subtitle {
    font-size: 2.4rem;
  }

  .rgu-selection__loader {
    display: none !important;
  }

  .rgu-selection__subtitle {
    @include mq($until: m) {
      max-width: 30rem;
      margin: 0 auto 3rem;
    }
  }

  .rgu-selection__rgus__item__title {
    @include mq($until: m) {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
}

.homepage__related {
  &.is-lightest-gray {
    background-color: $white !important;
  }

  ::v-deep .card-small__inner {
    @include mq($until: m) {
      padding-top: 0 !important;
    }
  }

  ::v-deep .card-small__title {
    @extend %fw-semi;
    margin: 0;
  }

  ::v-deep .card-small__description {
    width: 100%;

    @include mq($until: m) {
      max-width: 30rem;
    }
  }

  ::v-deep .generic-slider__slides {
    text-align: center;
  }

  ::v-deep .card-small-item {
    overflow: hidden;

    @include mq($until: m) {
      display: block;
    }
  }

  ::v-deep .card-small__link {
    margin-top: 3.6rem;
  }

  ::v-deep .card-small__items .flickity-button {
    margin-top: 0 !important;
  }
}

