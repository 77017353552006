
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


























































































































































































































































































































































/* stylelint-disable declaration-no-important */
.homepage-frame {
  position: relative;
  z-index: 1;
  padding-top: $spacing * 5;
  // padding-bottom: $spacing * 5;
  background: $c-blue-darkest;

  @include mq(l) {
    padding-top: $spacing * 7.5;
  }
}

.homepage-frame-inner {
  position: relative;
  margin-top: $spacing * 2.5;
}

.homepage-frame__title {
  @extend %text-center;

  position: relative;
  z-index: 9;
  max-width: 82rem;
  margin: 0 auto;
  margin-top: $spacing * 0.5;
  color: $white;
  font-size: 3rem;

  @include mq(l) {
    margin-top: $spacing;
    font-size: 7rem;
  }
}

.homepage-frame__section {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-inline: 3rem;
}

.homepage-frame__headline {
  @extend %text-center;

  position: relative;
  z-index: 9;
  color: $white;
  font-family: $ff-alt;
  font-size: 1.3rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include mq(l) {
    font-size: 1.8rem;
  }
}

.homepage-frame__section__title {
  @include fluid(
    margin-top,
    (
      s: 12px,
      l: 24px,
    )
  );

  max-width: 80rem;
  margin-bottom: 0;
  color: $white;
  font-size: 3rem;
  text-align: center;

  @include mq(m) {
    margin-top: $spacing * 1.2;
    font-size: 4.8rem;
  }

  @include mq(xl) {
    font-size: 6rem;
  }
}

.homepage-frame__section__btn {
  @include fluid(
    margin-top,
    (
      s: 28px,
      l: 40px,
    )
  );
  @include fluid(
    padding-top,
    (
      s: 14px,
      l: 16px,
    )
  );
  @include fluid(
    padding-bottom,
    (
      s: 14px,
      l: 16px,
    )
  );
  @include fluid(
    padding-left,
    (
      s: 24px,
      l: 30px,
    )
  );
  @include fluid(
    padding-right,
    (
      s: 24px,
      l: 30px,
    )
  );

  @include mq(l) {
    margin-top: $spacing * 2;
  }
}

.homepage-frame__canvas-outer {
  @include get-all-space;
}

.homepage-frame__canvas {
  @include image-fit;

  position: sticky;
  top: 0;
  max-height: 100vh;

  @include mq($until: m) {
    top: 5rem;
    max-height: 40rem;
  }
}
