
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




























































































































.homepage-myvoo {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: $spacing;

  @include mq(l) {
    z-index: 2;
    padding-top: $spacing;
    background-color: $c-cultured;

    &.is-white {
      background: linear-gradient(180deg, rgba($white, 0) 60%, $white 60%);
    }
  }

  @include mq(xxxl) {
    padding-top: $spacing;
  }
}

.homepage-myvoo-inner {
  @include fluid(
    padding-right,
    (
      s: 30px,
      l: 110px,
    )
  );

  position: relative;
  z-index: 1;
  max-width: 85rem;
  padding-bottom: $spacing * 2.5;
  padding-left: $spacing * 0.75;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 10px 40px rgba($black, 0.1);

  @include mq($until: l) {
    margin-bottom: 7.5rem;
    text-align: center;
  }

  @include mq(l) {
    display: flex;
    padding-top: $spacing * 2.5;
  }
}

.homepage-myvoo__picture {
  flex: 0 0 25rem;

  img {
    width: 25rem;
    height: auto;

    @include mq($until: l) {
      margin-top: -4rem;
    }

    @include mq(l) {
      position: absolute;
      top: -4rem;
      left: 0;
      width: 27rem;
    }
  }

  @include mq(l) {
    display: flex;
    flex: 0 0 27rem;
  }
}

.homepage-myvoo__content {
  @include mq(l) {
    padding-left: $spacing;
  }
}

.homepage-myvoo__title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 2.8rem;
}

.homepage-myvoo__text {
  margin: 0 auto $spacing * 1.25;

  @include mq(l) {
    max-width: 35em;
  }
}

.homepage-myvoo__btn {
  width: fit-content;

  @include mq($until: l) {
    margin: 0 auto $spacing * 0.5;
  }

  @include mq(l) {
    margin-bottom: $spacing * 0.5;
  }
}

.homepage-myvoo__speedlines {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 18rem;
  background-color: $c-cultured;

  img {
    @include image-fit(cover, top);
  }

  @include mq(xxxl) {
    height: 20rem;
  }
}

.homepage-myvoo__app {
  @include mq(l) {
    display: none;
  }
}
